import React from 'react';

const App = () => {
  return (
    <div className="max-w-3xl mx-auto p-6 font-sans">
      <header className="mb-12">
        <h1 className="text-2xl font-bold mb-2">Rudransh Tripathi</h1>
        <p className="text-2xl text-gray-600">Human, Problem Solver, Son</p>
      </header>

      <section className="mb-12">
        <h2 className="text-2xl font-semibold mb-4">About me</h2>
        <p className="text-lg leading-relaxed mb-4">
        <ul className="list-disc pl-6 mb-4">
         <li className="text-lg leading-relaxed mb-2">Born in Ahmedabad; multicultural background (Haldwani/Lucknow)</li>
         <li className="text-lg leading-relaxed mb-2">IIIT Nagpur graduate; passionate about math, tech, sports, and gaming</li>
         <li className="text-lg leading-relaxed mb-2">Ex-Developer at Groww, with internships at Groww, IIT Delhi, IIM Udaipur, Programming Pathshala, and Avtaar</li>
         <li className="text-lg leading-relaxed mb-2">Co-Founded two companies: Noswipe (failed) and Coffy (current)</li>
         <li className="text-lg leading-relaxed mb-2">Inspired by: Tesla, Feynman</li>
         <li className="text-lg leading-relaxed mb-2">Based in Bengaluru, Sarjapur</li>
         </ul>
        </p>
        <p className="text-lg leading-relaxed">
          I have explored a lot and learned much, but there are still countless things to discover. Now, I am focused on solving complex problems in the world.
        </p>
      </section>

      <section className="mb-12">
        <h2 className="text-2xl font-semibold mb-4">Entrepreneurial Journey</h2>
        <p className="text-lg leading-relaxed mb-4">
          Still early in my journey, but hungry to learn and build. Figuring things out. Gaining experience. Just trying.
        </p>
      </section>

      <section className="mb-12">
        <h2 className="text-2xl font-semibold mb-4">My Thoughts</h2>
        <p className="text-lg leading-relaxed mb-4">
          My journey so far has shaped these beliefs:
        </p>
        <ul className="list-disc pl-6 mb-4">
          <li className="text-lg leading-relaxed mb-2">
            Speed is a myth if you allow excuses, work alone.
          </li>
          <li className="text-lg leading-relaxed mb-2">
            Confidence is the food of the wise but the liquor of the fool.
          </li>
          <li className="text-lg leading-relaxed mb-2">
            Focus on the destination and not the vehicle.
          </li>
          <li className="text-lg leading-relaxed mb-2">
            Hard work is shit. Combine it with faster and smarter work.
          </li>
          <li className="text-lg leading-relaxed mb-2">
            Reach the product's 'Aha!' moment quickly and in an iconic, memorable way.
          </li>
          <li className="text-lg leading-relaxed mb-2">
            Comfort never brings change.
          </li>
          <li className="text-lg leading-relaxed mb-2">
            Be hard on yourself, not others.
          </li>
          <li className="text-lg leading-relaxed mb-2">
            Take the risks that make the world better.
          </li>
          <li className="text-lg leading-relaxed mb-2">
            People who work alone begin to understand spirituality.
          </li>
          <li className="text-lg leading-relaxed mb-2">
            Fail fast. Learn faster.
          </li>
          <li className="text-lg leading-relaxed mb-2">
            Vagueness doesn't build companies. Clarity is the key.
          </li>
          <li className="text-lg leading-relaxed">
            Product and distribution first, tech later.
          </li>
        </ul>
        <p className="text-lg leading-relaxed">
          My opinions, take them or leave them. I struggle with them too, and it kills me everytime.
        </p>
      </section>

      <section className="mb-12">
        <h2 className="text-2xl font-semibold mb-4">Contact</h2>
        <ul className="list-none pl-0">
          <li className="text-lg leading-relaxed mb-2">
            <span className="font-medium">Email:</span> <a href="mailto:hi@rudranshtripathi.in" className="text-blue-600 hover:underline">hi@rudranshtripathi.in</a>
          </li>
        </ul>
      </section>

    </div>
  );
};

export default App;